<template>
  <footer class="s-footer footer">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 footer-box">
          <nav class="footer-nav-wrap">
            <ul class="footer-main-nav">
              <li>
                <a class="smoothscroll" :href="baseUrl + '/#gloves'" title="gloves" v-smooth-scroll="{ duration: 1000, offset: -50 }">Gloves</a>
              </li>
              <li>
                <a class="smoothscroll" :href="baseUrl + '/#about'" title="about"  v-smooth-scroll="{ duration: 1000 }">About</a>
              </li>
              <li>
                <a class="smoothscroll" :href="baseUrl + '/#wlteam'" title="WL Team" v-smooth-scroll="{ duration: 1000, offset: -80 }">WL Team</a>
              </li>
              <li>
                <a class="smoothscroll" :href="baseUrl + '/#snowvideo'" title="Videos" v-smooth-scroll="{ duration: 1000, offset: -80 }">Videos</a>
              </li>
              <li>
                <a class="smoothscroll" :href="baseUrl + '/#partners'" title="Partners" v-smooth-scroll="{ duration: 1000 }">Partners</a>
              </li>
            </ul>
          </nav>
          <!-- end header-nav-wrap -->
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <div class="row">
        <div class="col-xs-12 ss-copyright text-center">
          <span>&copy; Copyright 2019 Wells Lamont, Inc. All Rights Reserved. Privacy Policy | Terms &amp; Conditions</span>
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
    <div :class="'go-top ' + linkVisible">
      <a class="smoothscroll" title="Back to Top" href="#home" v-smooth-scroll="{ duration: 1000, offset: -50 }"></a>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterMain",
  data() {
    return {
      linkVisible : ''
    }
  },
  mounted() {
    this.$root.$on("toggleStuff", (on) => {
      this.linkVisible = on ? 'link-is-visible' : '';
    });
  }
};
</script>

