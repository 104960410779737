import axios from "axios";
const baseURL = process.env.VUE_APP_WP_BASE
  ? "https://" + process.env.VUE_APP_WP_BASE
  : "https://snow.covertnine.com";
const baseNamespace = "/wp-json/wp/v2";
const snowNamespace = "/wp-json/wlsnow";
const per_page = process.env.VUE_APP_WP_PAGE_LIMIT
  ? process.env.VUE_APP_WP_PAGE_LIMIT
  : 100;

// wp-api params are defined here: http://v2.wp-api.org/reference/posts/
// using ES6 property-value shorthand for the object literal parameters

export const WpApi = () => {
  return axios.create({
    baseURL
  });
};

export const TeamService = {
  fetchTeam() {
    return WpApi().get(baseNamespace + "/team-api", { params: { per_page } });
  }
};

export const MemberService = {
  fetchMember(slug) {
    return WpApi().get(baseNamespace + "/team-api", { params: { slug } });
  }
};

export const ProductsService = {
  fetchProducts() {
    return WpApi().get(baseNamespace + "/products-api", {
      params: { per_page }
    });
  }
};

export const ProductService = {
  fetchProduct(slug) {
    return WpApi().get(baseNamespace + "/products-api", { params: { slug } });
  }
};

export const InstagramService = {
  fetchFeed(slug) {
    return WpApi().get(snowNamespace + "/instagram", { params: { slug } });
  }
};
export const YoutubeService = {
  fetchFeed() {
    return WpApi().get(snowNamespace + "/youtube");
  }
};
